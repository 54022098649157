.popupContainer {
  border: 2px solid #333996;
  background: #ffffff;
  border-radius: 5px;
  padding: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.actionContainer {
  display: flex;
  flex-direction: column;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
.otpErrorMessage {
  @extend .errorMessage;
  text-align: center;
}

.otpInput {
  margin-top: 30px;
}

.retryMsg {
  text-align: center;
  color: #333996;
  margin-top: 30px;
}
