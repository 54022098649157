

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



:root {
  --doc-height: 100%;
  --color-primary-20: #e8e8fc;
  --color-primary-30: #9999ff;
  --color-primary-40: #6464ff;
  --color-primary-50: #3535f3;
  --color-primary-60: #000093;
  --color-primary-70: #00004c;
  --color-primary-80: #010029;
  --color-primary-inverse: #ffffff;
  --color-primary-background: #ffffff;
  --color-primary-grey-100: #141414;
  --color-primary-grey-80: rgba(0, 0, 0, 0.65);
  --color-primary-grey-60: #b5b5b5;
  --color-primary-grey-40: #e0e0e0;
  --color-primary-grey-20: #f5f5f5;
  --color-secondary-20: #fef7e9;
  --color-secondary-30: #ffe3ae;
  --color-secondary-40: #ffd947;
  --color-secondary-50: #f7ab20;
  --color-secondary-60: #ac660c;
  --color-secondary-70: #7f4b10;
  --color-secondary-80: #401d0c;
  --color-secondary-inverse: #401d0c;
  --color-secondary-background: #ffffff;
  --color-secondary-grey-100: #141414;
  --color-secondary-grey-80: rgba(0, 0, 0, 0.65);
  --color-secondary-grey-60: #b5b5b5;
  --color-secondary-grey-40: #e0e0e0;
  --color-secondary-grey-20: #f5f5f5;
  --color-error: #ef5350;
}

@font-face {
  font-family: "JioType";
  src: local('JioType'), url(./assets/fonts/JioType-Light.ttf) format('ttf');
}

@font-face {
  font-family: "JioType";
  src: url(./assets/fonts/JioType-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "JioType";
  src: url(./assets/fonts/JioType-Medium.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "JioType";
  src: url(./assets/fonts/JioType-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "JioType";
  src: url(./assets/fonts/JioType-Black.ttf);
  font-weight: 900;
}