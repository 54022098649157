.payModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  padding: 24px;
  // max-height: 500px;
  max-width: 540px;
  border-radius: 32px;
  overflow: auto;
}
