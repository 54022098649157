.page-wrapper {
  padding: 12px;
}

.dfc {
  display: flex;
  justify-content: center;
}

.dfjs {
  display: flex;
  justify-content: space-between;
}
